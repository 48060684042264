import React from "react";
import "./styles.css";

const ctaWpp = (props) => {
  return (
    <figure id="ctaWpp">
      <a
        href="https://s.tintim.app/whatsapp/4647f02c-9405-420b-99b7-812832ad4e43/09e48d03-e0f7-4832-9b8c-e44db3a310aa"
        target="_blank"
        rel="noreferrer"
      >
        <img src={props.cta} alt="Chamada de ação para Whatsapp" />
      </a>
    </figure>
  );
};

export default ctaWpp;
